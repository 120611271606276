/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-13 18:43:42
 * @Module: 学习中心
 */
 <template>
  <div class="college-box"
       v-loading="dataLoading||loading">
    <div class="title">
      <div>今日学习：<span>{{data.records_today.value}}{{data.records_today.unit}}</span>
      </div>
      <div>积累学习：<span>{{data.records_count.value}}{{data.records_count.unit}}</span>
      </div>
    </div>
    <div class="college-content">
      <div class="college-content-title">学习记录</div>
      <div class="college-list">
        <div class="college-list-item"
             @click="to_detail(item.course_uuid)"
             v-for="(item,index) in list"
             :key="index">
          <el-image style="width:170px;height:98px;border-radius: 4px;"
                    :src="item.course_cover" />
          <div class="college-list-item-center">
            <div class="ellipsis">{{item.course_title}}</div>
            <div>已学习：{{item.total_progress}}分钟</div>
            <div>时长：{{item.total_duration}}分钟</div>
          </div>
          <div class="college-list-item-btn hover">继续学习</div>
        </div>
        <el-pagination layout="prev, pager, next"
                       style="display: flex;justify-content: center;"
                       v-show="pagination.page"
                       :current-page="pagination.page"
                       @current-change="currentChange"
                       :page-size="pagination.pageSize"
                       hide-on-single-page
                       :total="pagination.total">
        </el-pagination>
        <no-data v-show="pagination.total==0&& !loading"></no-data>
      </div>
    </div>
  </div>
</template>
 <script>
import noData from "@/components/noData"
export default {
  components: {
    noData
  },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 20,
        page: null
      },
      list: [],
      loading: true,
      dataLoading: true,
      data: {
        records_today: 0,
        records_count: 0
      }
    };
  },
  mounted () {
    this._get_list()
    this._get_data()
  },
  methods: {
    currentChange (page) {
      this._get_list(page)
    },
    async _get_list (page = 1) {
      this.loading = true
      const { status, info, data, meta } = await this.$api.courseStudyHistoryApi({ page })
      this.loading = false
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        this.pagination.page = meta.pagination.current_page
      } else {
        this.$message.error(info)
      }

    },
    async _get_data () {
      this.dataLoading = true
      const { data, status } = await this.$api.courseStudyCountApi()
      this.dataLoading = false
      if (status == 200)
        this.data = data
    },
    to_detail (course_id) {
      this.$router.push({
        name: "collegedetial",
        query: { course_id }
      })
    },
  },
};
 </script>
 <style lang='scss' scoped>
.college-box {
  background: #fff;
  .title {
    height: 58px;
    margin: 0 20px;
    border-bottom: #eee solid 1px;
    display: flex;
    align-items: center;
    div {
      font-size: 18px;
      color: #666666;
      margin-right: 40px;
      span {
        color: #262626;
      }
    }
  }
}
.college-content {
  &-title {
    height: 70px;
    padding-left: 20px;
    line-height: 70px;
    font-size: 20px;
    color: #262626;
  }
  .college-list {
    margin-top: -20px;
    min-height: 300px;
    &-item {
      &:last-child {
        border-bottom: none;
      }
      height: 138px;
      margin: 0 20px;
      border-bottom: #eee 1px solid;
      display: flex;
      align-items: center;
      &-center {
        width: 520px;
        margin-left: 12px;
        :nth-child(1) {
          font-size: 16px;
          color: #262626;
        }
        :nth-child(2) {
          margin-top: 10px;
          font-size: 14px;
          color: #999999;
        }
        :nth-child(3) {
          margin-top: 18px;
          font-size: 14px;
          color: #999999;
        }
      }
      &-btn {
        width: 140px;
        background: #ffffff;
        border-radius: 20px;
        border: 1px solid #df2a29;
        color: #df2a29;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
      }
    }
  }
}
</style>